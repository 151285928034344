<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Top Ten Consume Item </h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
              <li>
                <button @click="print('printArea','Item List')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">

                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <ItemTable :items="items" />


                    <div id="printArea" style="display: none">
                      <div style="text-align: center;line-height: 20px;">
                        <h2>{{ appName }}</h2>
                        <h4>Vessel Name: {{this.vessel_name}}</h4>
                        <div class="font-weight-bold">Item List</div>
                        <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        <ItemTable :items="items"   :isPrinting="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

    import Multiselect from 'vue-multiselect'
    import Select2 from 'v-select2-component'
    import QrcodeVue from 'qrcode.vue'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import auth from '../../../auth'
    import ItemTable from "@/components/Item/TopTenConsumeItem/ItemTable.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import printMixin from "@/mixin/printMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin, goBackButtonMixin, printMixin],
        name: 'TopTenConsumeItem',
        components: {
            ItemTable,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                permissions: auth.getPermissionData(),
                vesselId: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                items: [],
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                this.loading = true;
                let params = {
                    vessel_id: this.vesselId,
                };
                this.$http.get('/api/top-ten-consume-item/', {params}).then(response => {
                    this.items = response.data.success;
                    this.loading = false
                }).catch(e => {
                    console.log(e);
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },

        }
    }
</script>
<style>

</style>
