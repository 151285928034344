<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="x_panel">
            <h2 class="float-left">Requisition List</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button type="button" v-if="isPermissionExist(PERMISSIONS.REQUISITION_ADD)" class="btn btn-info btn-sm" @click="showRequisitionModal()"> Add Requisition <i class="fa fa-plus"></i></button>
              </li>
              <li>
                <button @click="print('printArea','Requisition List')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="card-box table-responsive">
                  <p class="text-muted font-13 m-b-30"></p>

                  <TableData
                    :requisitionData="requisitionData"
                    :approveRequest="approveRequest"
                    :showBudget="showBudget"
                    :show="show"
                    :viewQuotation="viewQuotation"
                    :edit="edit"
                    :destroy="destroy"
                    :copy="copy"
                  />
                  <div id="printArea" style="display: none">
                    <div style="text-align: center;line-height: 20px;">
                      <h2>{{ appName }}</h2>
                      <h4>Vessel Name: {{this.vessel_name}}</h4>
                      <div class="font-weight-bold">Requisition List</div>
                      <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                    </div>
                    <TableData :requisitionData="requisitionData" :isPrinting="true"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Requisition view Modal -->
      <div class="modal fade" id="addRequisitionModal" tabindex="-1" role="dialog" aria-labelledby="addRequisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addRequisitionModalTitle">Add Requisition </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <form role="form" action="#" method="POST" @submit="formSubmit" class="requisitionForm">

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Req Title<span class="required">*</span></label>
                      <input v-model="RequisitionTitle" type="text" name="req_title" class="form-control form-control-sm" placeholder="Enter title" autocomplete="off">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Requisition Priority</label>
                      <select v-model="RequisitionPriority" class="form-control form-control-sm">
                        <option value="">Select one</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Normal">Normal</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Requisition Type</label>
                      <select v-model="requisitionForm.type" class="form-control form-control-sm">
                        <option value="Others">Others</option>
                        <option value="Return">Return</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="remark">Remarks</label>
                      <textarea v-model="RequisitionRemarks" class="form-control form-control-sm" name="remark" rows="2" id="remark"></textarea>
                    </div>
                  </div>

                  <div class="x_panel">
                    <div class="x_content">
                      <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="line-tab" data-toggle="tab" href="#line" role="tab" aria-controls="line" aria-selected="false">
                            Line Items
                            <span class="btn btn-warning btn-sm pull-right" style="margin-top: -5px;">Add from Catalogue</span>
                            <span class="btn btn-info btn-sm pull-right" @click="addItem()" style="margin-top: -5px;">Add Item</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link " id="supply-tab" data-toggle="tab" href="#supply" role="tab" aria-controls="supply" aria-selected="true">Supply Details</a>
                        </li>

                      </ul>

                      <div class="tab-content mt-2" id="myTabContent">

                        <div class="tab-pane fade active show" id="line" role="tabpanel" aria-labelledby="line-tab">
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Category</label>
                                <select class="form-control form-control-sm" v-model="category_id" @change="getCategoriesItem">
                                  <option value="" selected>Select category</option>
                                  <option v-for="(row, key) in CategoryData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Select Item<span class="required">*</span></label>
                                <select class="form-control form-control-sm" v-model="itemId" @change="getItemData">
                                  <option value="" selected>Select item</option>
                                  <option v-for="(row, key) in CategoriesItemData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Status</label>
                                <select v-model="itemStatus" class="form-control form-control-sm">
                                  <option value="" selected>Select one</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Normal">Normal</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Quantity<span class="required">*</span></label>
                                <input v-model="itemQuantity" type="number" name="name" class="form-control form-control-sm" placeholder="Enter quantity" autocomplete="off">
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Unit</label>
                                <input v-model="itemData.item.unit.name" type="text" name="name" class="form-control form-control-sm" placeholder="Enter unit" readonly>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <button style="margin-top: 26px" type="button" class="btn btn-success btn-sm pull-right" @click="setRequisitionItem"><i class="fa fa-plus-square"></i>
                              </button>
                            </div>

                            <div class="col-md-12">
                              <table class="table table-bordered">
                                <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Item name</th>
                                  <th>Impa code</th>
                                  <th>Maker Part no</th>
                                  <th>Maker</th>
                                  <th>Model</th>
                                  <th>Qty</th>
                                  <th>Unit</th>
                                  <th>Stock</th>
                                  <th>Budget total</th>
                                  <th>Budget left</th>
                                  <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item , index) in requisitionForm.item_id" :key="index">
                                  <td>{{requisitionForm.status[index]}}</td>
                                  <td>{{requisitionForm.item_name[index]}}</td>
                                  <td>{{requisitionForm.item[index].item.inpa_code}}</td>
                                  <td>{{requisitionForm.item[index].item.maker_part_no}}</td>
                                  <td>{{requisitionForm.item[index].item.maker}}</td>
                                  <td>{{requisitionForm.item[index].item.model}}</td>
                                  <td>{{requisitionForm.qty[index]}}</td>
                                  <td>{{requisitionForm.item[index].item.unit.name}}</td>
                                  <td>{{requisitionForm.item[index].currentStock?.rob}}</td>
                                  <td>{{requisitionForm.item[index].totalBudget}}</td>
                                  <td>{{requisitionForm.item[index].totalBudgetLeft}}</td>
                                  <td>
                                    <button type="button" class="btn btn-round btn-danger" @click="removeRequisitionItem(index)"><i class="fa fa-trash-o"></i></button>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade " id="supply" role="tabpanel" aria-labelledby="supply-tab">
                          <div class="row">

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Country<span class="required">*</span></label>
                                <select class="form-control form-control-sm" v-model="country_id" @change="getCountriesport">
                                  <option value="" selected>Select country</option>
                                  <option v-for="(row, key) in CountryData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Port<span class="required">*</span></label>
                                <select class="form-control form-control-sm" v-model="port_id">
                                  <option value="" selected>Select port</option>
                                  <option v-for="(row, key) in portData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="address">Address<span class="required">*</span></label>
                                <textarea v-model="SupplyAddress" class="form-control form-control-sm" name="address" rows="2" id="address"></textarea>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Required By</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyDate"></datetime>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Vessel ETA</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETA"></datetime>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Vessel ETD</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETD"></datetime>
                              </div>
                            </div>

                            <!--                                        <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <label>Advised on Date</label>
                                                                            <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyAdvised"></datetime>
                                                                        </div>
                                                                    </div>-->
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-12">
                    <button :disabled="isDisabled" type="submit" class="btn btn-primary pull-right">Submit</button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Requisition view Modal -->

      <!-- Requisition view Modal -->
      <div class="modal fade" id="requisitionModal" tabindex="-1" role="dialog" aria-labelledby="requisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="requisitionModalTitle">Requisition Item </h5>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('jobDetailsArea','Requisition Item')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>

              </div>

            </div>
            <div class="modal-body">
              <ModalData :requisitionItem="requisitionItem"/>
              <div id="jobDetailsArea" style="display: none">
                <div style="text-align: center">
                  <h2>{{ appName }}</h2>
                  <h4>Vessel Name: {{this.vessel_name}}</h4>
                  <h3>Requisition Item </h3>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <ModalData :requisitionItem="requisitionItem" :isPrinting="true"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Requisition view Modal -->

      <!-- Requisition edit Modal -->
      <div class="modal fade" id="requisitionEditModal" tabindex="-1" role="dialog" aria-labelledby="requisitionEditModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="requisitionEditModalTitle">Update Requisition Item </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <ul class="stats-overview">
                <li>
                  <span class="name"> Requisition No </span>
                  <span class="value text-success"> {{requisitionItem.requisition_no}} </span>
                </li>
                <li>
                  <span class="name"> Status </span>
                  <span class="value text-success"> {{requisitionItem.status}} </span>
                </li>
                <li class="hidden-phone">
                  <span class="name"> Date </span>
                  <span class="value text-success"> {{requisitionItem.date}} </span>
                </li>
              </ul>

              <form role="form" action="#" method="POST" @submit="formUpdate" class="requisitionForm">

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Req Title</label>
                      <input v-model="RequisitionTitle" type="text" name="req_title" class="form-control form-control-sm" placeholder="Enter title" autocomplete="off">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Requisition Priority</label>
                      <select v-model="RequisitionPriority" class="form-control form-control-sm">
                        <option value="">Select one</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Normal">Normal</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="remark">Remarks</label>
                      <textarea v-model="RequisitionRemarks" class="form-control form-control-sm" name="remark" rows="2" id="remarks"></textarea>
                    </div>
                  </div>

                  <div class="x_panel">
                    <div class="x_content">
                      <ul class="nav nav-tabs nav-pills nav-justified bg-light" id="myEditTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="supply-edit-tab" data-toggle="tab" href="#supplyEdit" role="tab" aria-controls="supplyEdit" aria-selected="true">Supply
                            Details</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="line-edit-tab" data-toggle="tab" href="#lineEdit" role="tab" aria-controls="lineEdit" aria-selected="false">Line Items</a>
                        </li>
                      </ul>

                      <div class="tab-content mt-2" id="myEditTabContent">
                        <div class="tab-pane fade active show" id="supplyEdit" role="tabpanel" aria-labelledby="supply-edit-tab">
                          <div class="row">

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Country</label>
                                <select class="form-control form-control-sm" v-model="country_id" @change="getCountriesport">
                                  <option value="" selected>Select country</option>
                                  <option v-for="(row, key) in CountryData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Port</label>
                                <select class="form-control form-control-sm" v-model="port_id">
                                  <option value="" selected>Select port</option>
                                  <option v-for="(row, key) in portData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="address">Address</label>
                                <textarea v-model="SupplyAddress" class="form-control form-control-sm" name="address" rows="2" id="addresse"></textarea>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Supply Date</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyDate"></datetime>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Vessel ETA</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETA"></datetime>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Vessel ETD</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETD"></datetime>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Advised on Date</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyAdvised"></datetime>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade" id="lineEdit" role="tabpanel" aria-labelledby="line-edit-tab">
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Category Items</label>
                                <select class="form-control form-control-sm" v-model="category_id" @change="getCategoriesItem">
                                  <option value="" selected>Select category</option>
                                  <option v-for="(row, key) in CategoryData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Requisition Items</label>
                                <select class="form-control form-control-sm" v-model="itemId" @change="getItemData">
                                  <option value="" selected>Select item</option>
                                  <option v-for="(row, key) in CategoriesItemData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Status</label>
                                <select v-model="itemStatus" class="form-control form-control-sm">
                                  <option value="" selected>Select one</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Normal">Normal</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Quantity *</label>
                                <input v-model="itemQuantity" type="number" name="name" class="form-control form-control-sm" placeholder="Enter quantity" autocomplete="off">
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Unit</label>
                                <input v-model="itemData.item.unit.name" type="text" name="name" class="form-control form-control-sm" placeholder="Enter unit" readonly>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <button style="margin-top: 26px" type="button" class="btn btn-success btn-sm pull-right" @click="setRequisitionItem"><i class="fa fa-plus-square"></i>
                              </button>
                            </div>

                            <div class="col-md-12">
                              <table class="table table-bordered">
                                <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Item name</th>
                                  <th>Impa code</th>
                                  <th>Maker Part no</th>
                                  <th>Maker</th>
                                  <th>Model</th>
                                  <th>Unit</th>
                                  <th>Qty</th>
                                  <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item , index) in requisitionItem.requisition_item" :key="index">
                                  <td>{{item.status}}</td>
                                  <td>{{item.item.name}}</td>
                                  <td>{{item.item.inpa_code}}</td>
                                  <td>{{item.item.maker_part_no}}</td>
                                  <td>{{item.item.maker}}</td>
                                  <td>{{item.item.model}}</td>
                                  <td>{{item.item.unit.name}}</td>
                                  <td>
                                    <input :value="`${item.qty}`" @change="onChangeQuantity(index, $event)" style="width:100px" type="number" step="any" min="0" name="name"
                                           class="form-control" placeholder="Enter quantity" autocomplete="off">
                                  </td>
                                  <td>
                                    <button type="button" class="btn btn-round btn-danger" @click="removeEditRequisitionItem(index)"><i class="fa fa-trash-o"></i></button>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary pull-right">Submit</button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Requisition view Modal -->

      <!-- Requisition copy Modal -->
      <div class="modal fade" id="requisitionCopyModal" tabindex="-1" role="dialog" aria-labelledby="requisitionCopyModal" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Create Requisition </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <form role="form" action="#" method="POST" @submit="formCopy" class="requisitionForm">

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Req Title</label>
                      <input v-model="RequisitionTitle" type="text" name="req_title" class="form-control form-control-sm" placeholder="Enter title" autocomplete="off">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Requisition Priority</label>
                      <select v-model="RequisitionPriority" class="form-control form-control-sm">
                        <option value="">Select one</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Normal">Normal</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="remark">Remarks</label>
                      <textarea v-model="RequisitionRemarks" class="form-control form-control-sm" name="remark" rows="2" id="remarks"></textarea>
                    </div>
                  </div>

                  <div class="x_panel">
                    <div class="x_content">
                      <ul class="nav nav-tabs nav-pills nav-justified bg-light" id="" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="line-copy-tab" data-toggle="tab" href="#lineCopy" role="tab" aria-controls="lineCopy" aria-selected="false">Line Items</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="supply-copy-tab" data-toggle="tab" href="#supplyCopy" role="tab" aria-controls="supplyCopy" aria-selected="true">Supply Details</a>
                        </li>
                      </ul>

                      <div class="tab-content mt-2" id="">

                        <div class="tab-pane fade active show" id="lineCopy" role="tabpanel" aria-labelledby="line-copy-tab">
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Category Items</label>
                                <select class="form-control form-control-sm" v-model="category_id" @change="getCategoriesItem">
                                  <option value="" selected>Select category</option>
                                  <option v-for="(row, key) in CategoryData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Requisition Items</label>
                                <select class="form-control form-control-sm" v-model="itemId" @change="getItemData">
                                  <option value="" selected>Select item</option>
                                  <option v-for="(row, key) in CategoriesItemData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Status</label>
                                <select v-model="itemStatus" class="form-control form-control-sm">
                                  <option value="" selected>Select one</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Normal">Normal</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Quantity *</label>
                                <input v-model="itemQuantity" type="number" name="name" class="form-control form-control-sm" placeholder="Enter quantity" autocomplete="off">
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Unit</label>
                                <input v-model="itemData.item.unit.name" type="text" name="name" class="form-control form-control-sm" placeholder="Enter unit" readonly>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <button style="margin-top: 26px" type="button" class="btn btn-success btn-sm pull-right" @click="setRequisitionItem"><i class="fa fa-plus-square"></i>
                              </button>
                            </div>

                            <div class="col-md-12">
                              <table class="table table-bordered">
                                <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Item name</th>
                                  <th>Impa code</th>
                                  <th>Maker Part no</th>
                                  <th>Maker</th>
                                  <th>Model</th>
                                  <th>Unit</th>
                                  <th>Qty</th>
                                  <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item , index) in requisitionItem.requisition_item" :key="index">
                                  <td>{{item.status}}</td>
                                  <td>{{item.item.name}}</td>
                                  <td>{{item.item.inpa_code}}</td>
                                  <td>{{item.item.maker_part_no}}</td>
                                  <td>{{item.item.maker}}</td>
                                  <td>{{item.item.model}}</td>
                                  <td>{{item.item.unit.name}}</td>
                                  <td>
                                    <input :value="`${item.qty}`" @change="onChangeCopyQuantity(item, index, $event)" style="width:100px" type="number" step="any" min="0" name="name"
                                           class="form-control" placeholder="Enter quantity" autocomplete="off">
                                  </td>
                                  <td>
                                    <button type="button" class="btn btn-round btn-danger" @click="removeEditRequisitionItem(index)"><i class="fa fa-trash-o"></i></button>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade " id="supplyCopy" role="tabpanel" aria-labelledby="supply-copy-tab">
                          <div class="row">

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Country</label>
                                <select class="form-control form-control-sm" v-model="country_id" @change="getCountriesport">
                                  <option value="" selected>Select country</option>
                                  <option v-for="(row, key) in CountryData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Port</label>
                                <select class="form-control form-control-sm" v-model="port_id">
                                  <option value="" selected>Select port</option>
                                  <option v-for="(row, key) in portData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="address">Address</label>
                                <textarea v-model="SupplyAddress" class="form-control form-control-sm" name="address" rows="2" id="addresse"></textarea>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Supply Date</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyDate"></datetime>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Vessel ETA</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETA"></datetime>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Vessel ETD</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETD"></datetime>
                              </div>
                            </div>

                            <!--                          <div class="col-md-6">-->
                            <!--                            <div class="form-group">-->
                            <!--                              <label>Advised on Date</label>-->
                            <!--                              <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyAdvised"></datetime>-->
                            <!--                            </div>-->
                            <!--                          </div>-->
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-12">
                    <button :disabled="isDisabled" type="submit" class="btn btn-primary pull-right">Submit</button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Requisition view Modal -->

      <!-- Requisition quotation Modal -->
      <div class="modal fade" id="quotationModal" tabindex="-1" role="dialog" aria-labelledby="quotationModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="quotationModalTitle">Select Vendors </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <form role="form" action="#" method="POST" @submit="formRequestQuotation" class="requisitionForm">

                <table class="table table-bordered" id="vendorDataTable">
                  <thead>
                  <tr>
                    <th>
                      <label class="form-checkbox">
                        <input type="checkbox" v-model="selectAll" @click="selectSupplier">
                      </label>
                    </th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Country</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(supplier , index) in supplierData" :key="index">
                    <td>
                      <label class="form-checkbox">
                        <input type="checkbox" :value="supplier.id" v-model="selected">
                        <i class="form-icon"></i>
                      </label>
                    </td>
                    <td>{{supplier.id}}</td>
                    <td>{{supplier.name}}</td>
                    <td>{{supplier.email}}</td>
                    <td>{{supplier.contact_no}}</td>
                    <td>{{supplier?.country?.name}}</td>
                  </tr>
                  </tbody>
                </table>

                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary pull-right" :disabled="isDisabled">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Requisition view Modal -->

      <div class="modal fade" id="addItemModal" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <form @submit.prevent="store()" @keydown="form.onKeydown($event)" role="form">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add New Item </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <section>
                  <div class="">
                    <div class="tab-content">
                      <div class="tab-pane active" role="tabpanel" id="step1">
                        <div class="panel-heading">
                          <h3 class="panel-title text-center">Basic Information</h3>
                        </div>
                        <div class="row">
                          <div class="col-md-1 col-sm-1"></div>
                          <div class="col-md-5 col-sm-5">
                            <div class="form-group">
                              <label> Name<span class="required">*</span></label>
                              <input autocomplete="off" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                              <has-error :form="form" field="name"></has-error>
                            </div>
                            <div class="form-group">
                              <label> Category:<span class="required">*</span></label>
                              <select v-model="form.category_id" name="category_id" class="form-control category-tree" :class="{ 'is-invalid': form.errors.has('category_id') }">
                                <option v-for="category in CategoryData" :key="category.id" :value="category.id">{{category.name}}</option>
                              </select>
                              <has-error :form="form" field="category_id"></has-error>
                            </div>
                            <div class="form-group">
                              <label>Unit:<span class="required">*</span></label>
                              <select v-model="form.unit_id" name="unit_id" class="form-control" :class="{ 'is-invalid': form.errors.has('unit_id') }">
                                <option v-for="row in units" :key="row.id" :value="row.id">{{row.name}}</option>
                              </select>
                              <has-error :form="form" field="unit_id"></has-error>
                            </div>
                            <div class="form-group">
                              <label>Budget Head:<span class="required">*</span></label>
                              <Select2 v-model="form.budget_head_id" name="budget_head_id" :options="myOptions" :class="{ 'is-invalid': form.errors.has('budget_head_id') }"/>
                              <has-error :form="form" field="budget_head_id"></has-error>
                            </div>


                          </div>
                          <div class="col-md-5 col-sm-5">
                            <div class="form-group">
                              <label>Impa Code:<span class="required">*</span></label>
                              <input autocomplete="off" v-model="form.inpa_code" type="text" name="model" class="form-control"
                                     :class="{ 'is-invalid': form.errors.has('inpa_code') }">
                              <has-error :form="form" field="inpa_code"></has-error>
                            </div>
                            <div class="form-group">
                              <label>Location:<span class="required">*</span></label>
                              <select v-model="form.location_id" name="location_id" class="form-control location-tree" :class="{ 'is-invalid': form.errors.has('location_id') }">
                                <option v-for="location in locationList" :key="location.id" :value="location.id">{{location.name}}</option>
                              </select>
                              <has-error :form="form" field="location_id"></has-error>
                            </div>

                            <div class="form-group">
                              <label> Average Price:</label>
                              <input v-model="form.price" autocomplete="off" type="number" step="any" min="0" name="price" class="form-control"
                                     :class="{ 'is-invalid': form.errors.has('price') }">
                              <has-error :form="form" field="price"></has-error>
                            </div>

                            <div class="form-group">
                              <label>Model:<span class="required">*</span></label>
                              <input autocomplete="off" v-model="form.model" type="text" name="model" class="form-control" :class="{ 'is-invalid': form.errors.has('model') }">
                              <has-error :form="form" field="model"></has-error>
                            </div>

                          </div>
                          <div class="col-md-1 col-sm-1"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-1"></div>
                          <div class="col-md-10">

                            <div class="file-upload">
                              <!-- <button class="file-upload-btn" type="button" onclick="$('.file-upload-input').trigger( 'click' )">Add Image</button> -->

                              <div class="image-upload-wrap">
                                <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*"/>
                                <input type="hidden" value="" id="itemImage">
                                <div class="drag-text">
                                  <h3 style="padding: 10px 0;">Drag and drop an image or select image</h3>
                                </div>
                              </div>
                              <div class="file-upload-content">
                                <img class="file-upload-image" src="#" alt="your image"/>
                                <div class="image-title-wrap">
                                  <button type="button" onclick="removeUpload()" class="remove-image">Remove <span class="image-title">Uploaded Image</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-1"></div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-1"></div>
                          <div class="col-md-10">
                            <button :disabled="form.busy" type="submit" class="btn btn-primary pull-right">Submit</button>
                          </div>
                          <div class="col-md-1"></div>
                        </div>
                      </div>

                      <div class="clearfix"></div>
                    </div>
                  </div>
                </section>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
    import Select2 from 'v-select2-component'
    import datetime from 'vuejs-datetimepicker'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import ModalData from "./ModalData.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PrintMixin from "@/mixin/printMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin, goBackButtonMixin, PrintMixin],
        name: 'RequisitionItems',
        components: {
            ModalData,
            TableData,
            Select2,
            datetime,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                editMode: false,
                isDisabled: false,
                locationList: null,
                units: null,
                myOptions: [],
                vesselId: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                permissions: auth.getPermissionData(),
                CategoryData: [],
                category_id: '',
                country_id: '',
                port_id: '',
                RequisitionTitle: '',
                RequisitionPriority: '',
                RequisitionRemarks: '',
                SupplyAddress: '',
                SupplyDate: '',
                SupplyETA: '',
                SupplyETD: '',
                SupplyAdvised: '',
                itemId: '',
                itemName: '',
                itemStatus: '',
                itemQuantity: '',
                itemTotalPrice: '',
                CategoriesItemData: [],
                CountryData: [],
                portData: [],
                itemData: {
                    'item': {
                        'id': '',
                        'budget_head_id': '',
                        'category_id': '',
                        'location_id': '',
                        'vessel_id': '',
                        'other_ref': '',
                        'name': '',
                        'maker': '',
                        'maker_part_no': '',
                        'model': '',
                        'drawing': '',
                        'size': '',
                        'unit': '',
                        'description': '',
                        'min_order': '',
                        'max_order': '',
                        'reorder_level': '',
                        'reorder_qty': '',
                        'opening_stock': '',
                        'opening_stock_date': '',
                        'image': '',
                        'doc': '',
                        'status': '',
                        'created_at': '',
                        'updated_at': '',
                        'budgethead': {
                            'id': '',
                            'vessel_id': '',
                            'parent_id': '',
                            'code': '',
                            'group_code': '',
                            'name': '',
                            'created_at': '',
                            'updated_at': '',
                            'budget': {
                                'id': '',
                                'budget_head_id': '',
                                'amount': '',
                                'budget_year': '',
                                'created_at': '',
                                'updated_at': ''
                            }

                        }

                    },
                    'component': [],
                    'currentStock': '',
                    'lastRequisitionData': {
                        'id': '',
                        'requisition_id': '',
                        'item_id': '',
                        'qty': '',
                        'status': '',
                        'created_at': '',
                        'updated_at': ''

                    },
                    'lastPurchaseData': ''
                },
                form: new Form({
                    id: '',
                    category_id: '',
                    location_id: '',
                    supplier_id: '',
                    vessel_id: auth.getVesselId(),
                    other_ref: '',
                    name: '',
                    maker: '',
                    maker_part_no: '',
                    model: '',
                    drawing: '',
                    size: '',
                    unit_id: '',
                    description: '',
                    min_order: '',
                    max_order: '',
                    reorder_level: '',
                    reorder_qty: '',
                    opening_stock: '',
                    class: '',
                    opening_stock_date: '',
                    image: '',
                    doc: '',
                    inpa_code: '',
                    status: '',
                    component_id: [],
                    budget_head_id: '',
                    price: 0
                }),
                supplierData: [],
                requisitionData: [],
                requisitionItem: [],
                requisitionItems: [],
                requisitionForm: {
                    vessel_id: auth.getVesselId(),
                    type: '',
                    item_id: [],
                    item_name: [],
                    status: [],
                    qty: [],
                    item: [],
                },
                requestRequisitionId: '',
                selected: [],
                selectAll: false
            }
        },
        mounted() {
            this.getRequisitionData()
            this.getCategoryData()
            this.getCountryData()
            this.getBudgetHeadInfo()
            this.locationListInfo()
            this.getUnit()
        },
        methods: {
            getRequisitionData() {
                this.loading = true;
                this.$http.get('/api/filter/requisition/' + this.vesselId).then(response => {
                    this.loading = false;
                    this.requisitionData = response.data.success
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            getUnit() {
                this.$http.get('/api/unit').then(response => {
                    this.units = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            async getBudgetHeadInfo() {
                this.myOptions = [];
                var itemList = await this.$http.get('/api/filter/budget-head/' + this.form.vessel_id)
                this.results = itemList.data.success;
                var newObj = {
                    id: '',
                    text: 'No Selected'
                };
                this.myOptions.push(newObj);
                this.results.filter(item => {
                    var newObj = {
                        id: item.id,
                        text: item.name
                    }
                    this.myOptions.push(newObj)
                })
            },
            locationListInfo() {
                this.$http.get('/api/locationTree/' + this.form.vessel_id).then(response => {
                    $('.location-tree').html(response.data)
                }).catch(e => {
                    console.log(e)
                })
            },
            store() {
                this.form.busy = true;
                this.form.image = $('#itemImage').val();
                this.form.post('/api/item').then(response => {
                    $('#roleModal').modal('hide');
                    if (response.data.success) {
                        $('#addItemModal').modal('hide');
                        this.CategoriesItemData = response.data.success;
                        this.category_id = response.data.success.category_id;
                        this.getCategoriesItem();
                    } else {
                        this.$snotify.error('Please check form again and fill-up all required filed.')
                    }
                }).catch(e => {
                    console.log(e);
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        var error = this.form.errors.errors.error;
                        this.$snotify.error('Please check form again and fill-up all required filed.')
                    }
                })
            },
            getSupplierData() {
                this.$http.get('/api/active-supplier').then(response => {
                    this.supplierData = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getCategoryData() {
                this.$http.get('/api/filter/category/create/' + this.vesselId).then(response => {
                    this.CategoryData = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getCategoriesItem() {
                this.$http.post('/api/item-filter', {
                    category_id: this.category_id,
                    vessel_id: this.vesselId
                }).then(response => {
                    this.CategoriesItemData = response.data;
                }).catch(e => {
                    this.CategoriesItemData = [];
                    console.log(e)
                })
            },
            getCountryData() {
                this.$http.get('/api/country').then(response => {
                    this.CountryData = response.data.success

                }).catch(e => {
                    console.log(e)
                })
            },
            getCountriesport() {
                this.$http.get('/api/port-by-country/' + this.country_id).then(response => {
                    this.portData = response.data.success

                }).catch(e => {
                    this.portData = []
                    console.log(e)
                })
            },
            getItemData() {
                this.$http.get('/api/item/' + this.itemId).then(response => {
                    this.itemData = response.data;
                    this.itemName = response.data.item.name;
                }).catch(e => {
                    console.log(e)
                })
            },
            showRequisitionModal() {
                $('#addRequisitionModal').modal('show')
            },
            addItem() {
                window.location.href = '/item'
                // $('#addItemModal').modal('show')
            },
            setRequisitionItem() {
                if (!this.category_id || !this.itemId || !this.itemStatus || !this.itemQuantity) {
                    this.$snotify.error('Please fill required field')
                } else {
                    // for edit requisition add new item
                    if (this.requisitionItem.id) {
                        let editItemData = {
                            requisition_id: this.requisitionItem.id,
                            item_id: this.itemId,
                            qty: this.itemQuantity,
                            status: this.itemStatus,
                            item: this.itemData.item
                        }
                        this.requisitionItem.requisition_item.push(editItemData)
                    } else {
                        if(this.itemData?.currentStock?.max_order < (parseInt(this.itemQuantity) + parseInt(this.itemData?.currentStock?.rob))){
                            alert('Item quantity will exceed  max order quantity ')
                        }

                        this.itemTotalPrice = (parseFloat(this.itemQuantity) * parseFloat(this.itemData.item.price))
                        if (this.itemTotalPrice > this.itemData.totalBudgetLeft) {
                            alert('Item value will exceed item budget value')
                        }


                        if (this.requisitionForm.item_id.includes(this.itemId)) {
                            this.$snotify.error('This requisition item already added')
                            this.itemId = ''
                            this.itemQuantity = ''
                            return false
                        }
                        this.message = false;
                        this.requisitionForm.item.push(this.itemData);
                        this.requisitionForm.item_id.push(this.itemId);
                        this.requisitionForm.item_name.push(this.itemName);
                        this.requisitionForm.status.push(this.itemStatus);
                        this.requisitionForm.qty.push(this.itemQuantity);
                    }
                    this.itemId = ''
                    this.itemName = ''
                    this.itemStatus = ''
                    this.itemQuantity = ''
                    this.itemTotalPrice = ''
                }
            },
            removeRequisitionItem(index) {
                if (confirm('Do you really want to delete?')) {
                    this.requisitionForm.item_id.splice(index, 1)
                    this.requisitionForm.item_name.splice(index, 1)
                    this.requisitionForm.status.splice(index, 1)
                    this.requisitionForm.item.splice(index, 1)
                    this.requisitionForm.qty.splice(index, 1)
                }
            },
            removeEditRequisitionItem(index) {
                if (confirm('Do you really want to delete?')) {
                    this.requisitionItem.requisition_item.splice(index, 1)
                }
            },
            onChangeItem(index, event) {
                this.requisitionItem.requisition_item[index].item_id = event.target.value
            },
            onChangeQuantity(index, event) {
                this.requisitionItem.requisition_item[index].qty = event.target.value;
            },
            onChangeCopyQuantity(item, index, event) {
                this.requisitionItem.requisition_item[index].item_id = item.item_id;
                this.requisitionItem.requisition_item[index].status = item.status;
                this.requisitionItem.requisition_item[index].qty = event.target.value;
            },
            formSubmit: function (e) {
                e.preventDefault();
                this.isDisabled = true;
                this.requisitionForm.title = this.RequisitionTitle
                this.requisitionForm.priority = this.RequisitionPriority
                this.requisitionForm.remarks = this.RequisitionRemarks
                this.requisitionForm.country_id = this.country_id
                this.requisitionForm.port_id = this.port_id
                this.requisitionForm.address = this.SupplyAddress
                this.requisitionForm.supply_date = this.SupplyDate
                this.requisitionForm.vessel_eta = this.SupplyETA
                this.requisitionForm.vessel_etd = this.SupplyETD
                this.requisitionForm.advised_on_date = this.SupplyAdvised

                if (!this.country_id) {
                    this.isDisabled = false;
                    this.$snotify.error('Country required')
                    return false
                }
                if (!this.port_id) {
                    this.isDisabled = false;
                    this.$snotify.error('Port required')
                    return false
                }
                if (!this.SupplyAddress) {
                    this.isDisabled = false;
                    this.$snotify.error('Supply address required')
                    return false
                }

                if (this.requisitionForm.item_id.length > 0) {
                    this.$http.post(`/api/requisition`, this.requisitionForm).then((response) => {
                        this.isDisabled = false;
                        this.requisitionForm = {}
                        if (response.data.success) {
                            this.$snotify.success('Requisition created successfully')
                            this.getRequisitionData()
                            $('#addRequisitionModal').modal('hide')

                        }
                    }).catch((error) => {
                        console.log("e", error)
                        this.isDisabled = false;

                        let response = e.response;
                        if (response?.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else if (error.response?.status) {
                            this.$snotify.error('Requisition failed, please try again later.')
                        }
                    })
                } else {
                    this.isDisabled = false;
                    this.$snotify.error('Please fill required field')
                }
            },
            formUpdate: function (e) {
                this.requisitionItem.title = this.RequisitionTitle
                this.requisitionItem.priority = this.RequisitionPriority
                this.requisitionItem.remarks = this.RequisitionRemarks
                this.requisitionItem.country_id = this.country_id
                this.requisitionItem.port_id = this.port_id
                this.requisitionItem.address = this.SupplyAddress
                this.requisitionItem.supply_date = this.SupplyDate
                this.requisitionItem.vessel_eta = this.SupplyETA
                this.requisitionItem.vessel_etd = this.SupplyETD
                this.requisitionItem.advised_on_date = this.SupplyAdvised

                if (!this.country_id) {
                    this.isDisabled = false;
                    this.$snotify.error('Country required')
                    return false
                }
                if (!this.port_id) {
                    this.isDisabled = false;
                    this.$snotify.error('Port required')
                    return false
                }
                if (!this.SupplyAddress) {
                    this.isDisabled = false;
                    this.$snotify.error('Supply address required')
                    return false
                }

                e.preventDefault()
                if (this.requisitionItem.requisition_item.length > 0) {
                    this.$http.post(`/api/requisition/update`,this.requisitionItem).then((response) => {
                        $('#requisitionEditModal').modal('hide');
                        if (response.data.success) {
                            this.$snotify.success('Requisition updated successfully');
                            this.requisitionItem = {};
                            this.getRequisitionData();
                        }
                    }).catch((error) => {
                        let response = e.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else if (error.response.status) {
                            this.$snotify.error('Update failed, please try again later.')
                        }
                    })
                } else {
                    this.isDisabled = false;
                    this.$snotify.error('Please select requisition item & quantity')
                }

            },
            formCopy: function (e) {
                e.preventDefault()
                this.isDisabled = true;
                this.requisitionItem.title = this.RequisitionTitle
                this.requisitionItem.priority = this.RequisitionPriority
                this.requisitionItem.remarks = this.RequisitionRemarks
                this.requisitionItem.country_id = this.country_id
                this.requisitionItem.port_id = this.port_id
                this.requisitionItem.address = this.SupplyAddress
                this.requisitionItem.supply_date = this.SupplyDate
                this.requisitionItem.vessel_eta = this.SupplyETA
                this.requisitionItem.vessel_etd = this.SupplyETD
                this.requisitionItem.advised_on_date = this.SupplyAdvised

                if (this.requisitionItem.requisition_item.length > 0) {
                    this.$http.post(`/api/requisition-copy/` + this.requisitionItem.id, this.requisitionItem).then((response) => {
                        this.isDisabled = false;

                        if (response.data.success) {
                            this.$snotify.success('Requisition created successfully')
                            this.requisitionItem = {}
                            this.getRequisitionData()
                            $('#requisitionCopyModal').modal('hide')
                        }
                    }).catch((error) => {
                        this.isDisabled = false;

                        if (error.response.status) {
                            this.$snotify.error('Creation failed, please try again later.')
                        }
                    })
                } else {
                    this.isDisabled = false;
                    this.$snotify.error('Please select requisition item & quantity')
                }
                $('#requisitionCopyModal').modal('hide')
            },
            viewQuotation(item) {
                this.requestRequisitionId = item
                this.isDisabled = false
                this.selected = []
                this.selectAll = false
                this.getSupplierData()
                setTimeout(() => $('#vendorDataTable').DataTable(), 1000)
                $('#quotationModal').modal('show')
            },
            show(item) {
                $('#requisitionModal').modal('show');
                this.$http.get('/api/requisition/' + item).then(response => {
                    this.requisitionItem = response.data
                }).catch(e => {
                    console.log(e)
                })
            },
            showBudget(id, index) {
                this.$http.get('/api/get-budget-info/' + id).then(response => {
                    $('.budget-' + index).html('Budget:' + response.data.totalBudget + ', Budget Left:' + response.data.totalBudgetLeft)
                }).catch(e => {
                    console.log(e);
                    this.$snotify.error(e.response.data.error);
                    $('.budget-' + index).html(e.response.data.error)
                })
            },
            edit(item) {
                $('#requisitionEditModal').modal('show');
                this.$http.get('/api/requisition/' + item).then(response => {
                    this.requisitionItem = response.data;
                    // this.getRequisitionData()
                    this.RequisitionTitle = this.requisitionItem.title;
                    this.RequisitionPriority = this.requisitionItem.priority;
                    this.RequisitionRemarks = this.requisitionItem.remarks;
                    this.country_id = this.requisitionItem.country_id;
                    this.port_id = this.requisitionItem.port_id;
                    this.SupplyAddress = this.requisitionItem.address;
                    this.SupplyDate = this.requisitionItem.supply_date;
                    this.SupplyETA = this.requisitionItem.vessel_eta;
                    this.SupplyETD = this.requisitionItem.vessel_etd;
                    this.SupplyAdvised = this.requisitionItem.advised_on_date;
                    this.getCountriesport();
                }).catch(e => {
                    console.log(e)
                })
            },
            copy(item) {
                $('#requisitionCopyModal').modal('show')
                this.$http.get('/api/requisition/' + item).then(response => {
                    this.requisitionItem = response.data
                    this.RequisitionTitle = this.requisitionItem.title
                    this.RequisitionPriority = this.requisitionItem.priority
                    this.RequisitionRemarks = this.requisitionItem.remarks
                    this.country_id = this.requisitionItem.country_id
                    this.port_id = this.requisitionItem.port_id
                    this.SupplyAddress = this.requisitionItem.address
                    this.SupplyDate = this.requisitionItem.supply_date
                    this.SupplyETA = this.requisitionItem.vessel_eta
                    this.SupplyETD = this.requisitionItem.vessel_etd
                    this.SupplyAdvised = this.requisitionItem.advised_on_date
                    this.getCountriesport()
                }).catch(e => {
                    console.log(e)
                })
            },
            destroy(item) {
                this.$dialog.confirm('Are you sure to delete?').then(() => {
                    this.$http.delete('/api/requisition/' + item).then(response => {
                        this.getRequisitionData()
                        this.$snotify.success('Requisition deleted successfully')
                    }).catch(error => {
                        var err = error.response.data.error
                        this.$snotify.error(err)
                    })
                }).catch(function () {
                })
            },
            selectSupplier() {
                this.selected = []
                if (!this.selectAll) {
                    for (let i in this.supplierData) {
                        this.selected.push(this.supplierData[i].id)
                    }
                }
            },
            formRequestQuotation: function (e) {
                e.preventDefault()
                if (this.selected.length > 0) {
                    const requestQuotationData = {
                        requisition_id: this.requestRequisitionId,
                        items: this.selected,
                    }
                    this.isDisabled = true
                    this.$http.post(`/api/request-for-quotation`, requestQuotationData).then((response) => {
                        $('#quotationModal').modal('hide')
                        this.isDisabled = false
                        if (response.data.success) {
                            this.$snotify.success('Request quotation created successfully')
                            this.requestRequisitionId = ''
                            this.selected = []
                            $('#quotationModal').modal('hide')
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.isDisabled = false
                        if (error.response.status) {
                            this.$snotify.error('Request quotation failed, please try again later.')
                        }
                    })
                } else {
                    this.$snotify.error('Please select at least one supplier')
                }
            },
            myChangeEvent(val) {
            },
            mySelectEvent({id, text}) {
                this.itemId = id
                this.itemName = text
            },

            /**
             * Requisition item request approve function
             * Recive requistion item id
             */
            approveRequest(type, ItemId, status, key) {
                this.$http.post(`/api/approve`, {
                    type: type,
                    status: status,
                    key: key,
                    ref_id: ItemId
                }).then((response) => {
                    if (response.data.success) {
                        this.getRequisitionData()
                        this.$snotify.success('Request Approved Successfully.')
                    }
                }).catch((error) => {
                    if (error.response.status) {
                        this.$snotify.error('Request Approval Failed')
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
